@import '/src/theme/theme-functions';
@import '/src/theme/theme-mixins';

.avatar {
  @include flex-row(0);
  width: 100%;

  &.is-reversed {
    flex-direction: row-reverse;
  }

  .hiro-avatar-image {
    flex: 1 1 50%;
    max-width: 50%;
  }

  .hiro-avatar-description {
    flex: 1 1 50%;
    background: get-theme-color(brand, primary);
    box-shadow: 0 0 20px 6px rgba(get-theme-color(brand, primary), .3);
    display: flex;
    flex-direction: column;
    place-content: space-between;
    padding: 15px;

    .hiro-avatar-name {
      color: get-theme-color(brand, primary-dark);
      font-weight: bold;
      font-size: .75em;
      visibility: hidden;
    }

    .hiro-avatar-title {
      color: get-theme-color(basic, white);
      text-transform: uppercase;
      font-size: .65em;
      letter-spacing: 5px;
      line-height: 20px;
    }

    .hiro-avatar-meta {
      @include flex-column(5px);

      .hiro-avatar-technology-logos {
        @include flex-row(10px);

        img {
          max-height: 15px;
        }
      }
    }
  }

  &.is-large {
    width: 100%;
    
  }
}