@import '/src/theme/theme-functions';
@import '/src/theme/theme-mixins';


.jobs-intro{
  padding-top: 200px;
}
.hiro-page-section {
  .hiro-job{
    background: get-theme-color(brand, primary-dark);
    border: 1px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(to right, get-theme-color(brand, primary-dark), get-theme-color(brand, primary));
    box-shadow: 0 0 20px 6px rgba(get-theme-color(brand, primary), .1);
    padding: 25px;
    @include flex-column(10px);
    place-content: start;
    align-items: start;
    text-decoration: none;
    transition: all .3s ease-in-out;
    .hiro-job-title{
       position: relative;
      color:white;
      margin:0;
    }
    .hiro-job-details{
      color:get-theme-color(basic, white);
      font-size: .85em;
    }
    
    &:hover{
      background:get-theme-color(brand, primary);
      transform: scale(1.025);
    }
  }
}



