@import './src/theme/theme-functions';
@import './src/theme/theme-mixins';

.hiro-page-section-heading {
  @include flex-column(15px);
  position:relative;
  .hiro-titles{
    @include flex-column(10px);
  }
  .hiro-title-split-action{
    @include flex-row(15px);
    place-content: space-between;
    align-items: center;
  }
  .hiro-title{
    font-size: 3em;
    color: white;
    margin: 0;
  }
  .hiro-subtitle{
    font-size: .75em;
    color: white;
    font-weight: normal;
    font-family: "Varela Round", sans-serif;
    transform: rotate(-90deg);
    position: absolute;
    top: 90px;
    left: -150px;
    text-transform: uppercase;
    letter-spacing: 5px;
  }
  .hiro-actions {
    @include flex-row(15px);
    margin-top: 25px;
  }
  .hiro-paragraph{
    color:white;
    margin-bottom: 15px;
  }
  .hiro-split-description{
    @include flex-row(25px);
    > div{
      flex: 1 1 50%;
    }
  }
}

.is-large{
  .hiro-page-section{
    .hiro-title {
      font-size: 2.7em;
    }
    .hiro-subtitle{
      position: relative;
      transform: none;
      top: 0;
      left: 0;
    }
  }
}

.is-tablet{
  .hiro-page-section{
    .hiro-title {
      font-size: 2.25em;
    }
    &.is-restricted{
      &.restrict-container{
        >div {
          width: 80%;
        }
      }

      &.restrict-content{
        >div {
          margin: 0 auto;
          width: 80%;
        }
      }
    }
    .hiro-section-content{
      max-width: 800px;
      margin: 0 auto;
    }

    .hiro-split-description{
      @include flex-column(25px);
      > div{
        flex: 1 1 100%;
      }
    }
  }
}
.is-mobile{
  .hiro-page-section{
    .hiro-title {
      font-size: 1.55em;
    }
    &.is-restricted{
      &.restrict-container{
        >div {
          margin: 0 auto;
          width: 100%;
          border-radius: $default-border-radius-extended;
        }
      }
      &.restrict-content{
        >div {
          margin: 0 auto;
          width: 100%;
        }
      }
    }

    .hiro-split-description{
      @include flex-column(25px);
      > div{
        flex: 1 1 100%;
      }
    }

  }
}
