@import '/src/theme/theme-functions';
@import '/src/theme/theme-mixins';

.hiro-candidate-card{
  box-shadow: 0 0 17px 4px rgb(63 137 199);
  background-color:white;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  @include flex-column(0px);
  border-radius:15px;
  max-width: 550px;
  min-width: 400px;
  position:relative;
  overflow:hidden;
  .hiro-graphic-left{
   
    @include flex-column(25px);
    padding: 50px 50px 35px 50px;
    place-content: center;
    align-items:center;
    text-align: center;
    h2,h3{
      margin:0;
      text-align: center;
    }
    h2{
      margin-bottom: 5px;
    }
    h3{
      text-transform: uppercase;
      font-size: .85em;
      color:get-theme-color(brand)
    }
  }
  .hiro-graphic-right{
    @include flex-row(15px);
    place-content:center;
    align-items:center;
    padding: 0 65px 50px 65px;
    .hiro-candidate-card-checklist-item{
      @include flex-row(10px);
      place-content:space-between;
      .value{
        color:get-theme-color(brand);
       
      }
      
      &:last-child{
        //@include border(1px, 0,0,0);
        padding-top:15px;
        margin-top:15px;
        .value{
          color:get-theme-color(brand);
          font-weight: bold;
        }
      }
    }
  }
  img{
    max-width:150px;
    max-height: 150px;
    border-radius: 50%;
    border: 5px solid white;
    @include box-shadow();
  }
}