@import "theme-variables";

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin truncate($truncation-boundary) {
  max-width: $truncation-boundary;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin box-shadow($shadow: 0 0 5px 5px rgba(0, 0, 0, $default-opacity-factor)) {
  box-shadow: $shadow;
}
@mixin border-radius($radius: $default-border-radius) {
  border-radius: $radius;
}

@mixin box-shadow($shadow: $default-box-shadow) {
  box-shadow: $shadow;
}

@mixin box-shadow-heavy() {
  box-shadow: $default-heavy-box-shadow;
}

@mixin font-family-light() {
  font-weight: 300;
}

@mixin font-family-regular() {
  font-weight: 400;
}
@mixin font-family-medium() {
  font-family: $default-font-family-medium;
  font-weight: 600;
}

@mixin font-family-bold() {
  font-weight: 900;
}

@mixin button-style(){
  background:get-theme-color(brand, primary);
  border-radius: 50px;
  border:none;
  color:get-theme-color(basic, white);
  font-family: $default-font-family-medium;
  font-size: .75rem;
  font-weight: 600;
  letter-spacing: 3px;
  min-height: 53px;
  padding: 0 25px;
  text-transform: uppercase;
  transition: all .3s ease-in-out;
  &:hover{
    animation: pulse 2s infinite;
    cursor:pointer;
  }
}

@mixin button-style-ghost(){
  background:transparent;
  border-radius: 50px;
  border:2px solid get-theme-color(brand, primary);
  color:get-theme-color(brand, primary);
  font-family: $default-font-family-medium;
  font-size: .75rem;
  font-weight: 600;
  letter-spacing: 3px;
  min-height: 53px;
  padding: 0 25px;
  text-transform: uppercase;
  transition: all .3s ease-in-out;
  &:hover{
    animation: pulse 2s infinite;
    background:get-theme-color(brand, primary);
    color:get-theme-color(basic, white);
    cursor:pointer;
  }
}

@mixin pseudo($display: block, $pos: absolute, $content: "") {
  content: $content;
  display: $display;
  position: $pos;
}

@mixin border(
  $top-left: 1px,
  $top-right: 1px,
  $bottom-right: 1px,
  $bottom-left: 1px,
  $color: get-theme-color(borders)
) {
  border-style: solid;
  border-color: $color;
  border-width: $top-left $top-right $bottom-right $bottom-left;
}


@mixin full-height() {
  display: flex;
  flex-direction: column;
  height: 100%;
}

@mixin flex-content() {
  display: flex;
  flex-direction: column;
  gap: 15px;
  overflow-y: auto;
}


@mixin well-defaults() {
  background: get-theme-color(basic, white);
  display: block;
  @include border();
  @include border-radius();
  padding: 15px;
  position: relative;
  z-index: 0;
}


@mixin input-placeholder {
  &.placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
  &::-webkit-input-placeholder {
    @content;
  }
}

@mixin scrollbar-defaults() {
  @include scrollbars(
                  8px,
                  get-theme-color(brand),
                  rgba(darken(get-theme-color(brand, primary-dark), 5), 0.1)
  );
}

@mixin scrollbars(
  $size,
  $foreground-color,
  $background-color: mix($foreground-color, white, 50%)
) {
  // For Google Chrome
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
    border-radius: 0;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
  }

  // For Internet Explorer
  & {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }

  // Firefox support
  scrollbar-color: $foreground-color $background-color;
  scrollbar-width: thin;
}

@mixin flex-row($gap:0){
  display:flex;
  flex-direction:row;
  gap:$gap;
}

@mixin flex-column($gap:0){
  display:flex;
  flex-direction:column;
  gap:$gap;
}