@import '/src/theme/theme-functions';
@import '/src/theme/theme-mixins';

.hire-with-hiro-graphic{
  background:white;
  background-size:contain;
  border-radius: 25px 5px;
  
  .hire-with-hiro-content{
    position:relative;
  }
}
.hiro-our-process-candidate{
  max-width: 95px;
  border-radius: 50%;
  border: 5px solid white;
  position:absolute;
  &:nth-child(4){
    top: 55px;
    left: 5px;
    max-width: 55px;
  }
  &:nth-child(2){
    bottom: 65px;
    left: -35px;
  }
  &:nth-child(3){
    bottom: 150px;
    right: -40px;
    max-width: 75px;
  }
}
.hiro-our-process-infographic-img{
  max-width: 350px;
  border: double 75px transparent;
  border-radius: 50%;
  background-image: linear-gradient(get-theme-color(brand, primary-dark), get-theme-color(brand, primary-dark)), radial-gradient(circle at top left, rgba(lighten(get-theme-color(brand, primary-dark), 6%), .04), rgba(get-theme-color(brand, primary-dark), .12));
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.hiro-our-process-technical-graphic{
  width: 100px;
  display: flex;
  gap: 10px;
  place-content:center;
  background: get-theme-color(brand, primary-dark);
  box-shadow: 0px 0px 11px 4px rgba(0, 139, 201, .1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  padding: 25px;
  border-radius: 10px;
  position: absolute;
  top: 90px;
  right: -80px;
  img{
    max-height: 20px;
  }
}


.is-large {
  .hiro-our-process-infographic-img{  
  }
}

.is-tablet {
  
}

.is-mobile {
  .hire-with-hiro-graphic{
    display:none;
  }
}