@import '/src/theme/theme-functions';
@import '/src/theme/theme-mixins';

.how-hiro-works{
  .hiro-title, .hiro-subtitle, .hiro-paragraph{
    color:get-theme-color(brand, primary-dark);
    text-align:center;
  }
  .hiro-actions{
    place-content:center;
  }
}

.is-large .hiro-page-section {
  &.how-hiro-works {
    padding: 125px;
    flex-direction: column;
  }
}