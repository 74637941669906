@import '/src/theme/theme-functions';
@import '/src/theme/theme-mixins';

.hiro-mobile-navigation {
  .hiro-navigation {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: get-theme-color(brand, primary-dark);
    @include flex-column(25px);
    place-content: center;
    align-items: center;
  }
}
.hiro-mobile-navigation-trigger{
  color:white;
  padding: 35px 25px 25px 25px;
}
.hiro-close-mobile-navigation-trigger{
  position: absolute;
  top: 35px;
  right: 50px;
  color:white;
}