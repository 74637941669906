@import './src/theme/theme-functions';
@import './src/theme/theme-variables';

body {
  margin: 0;
  font-family: $default-font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18px;
  background:get-theme-color(brand, primary-dark);
}

h1{
  font-size: 3.5rem;
}

h2{
  font-size: 2.5rem;
}

h3{
  font-size: 1.5rem;
}

h4{
  font-size: 1.25rem;
}
p{
  color:get-theme-color(text);
  line-height: 30px;
}

@keyframes pulse{
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(get-theme-color(brand, primary), 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(get-theme-color(brand, primary), 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(get-theme-color(brand, primary), 0);
  }
}

@keyframes pulse-primary{
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(get-theme-color(brand, primary), 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(get-theme-color(brand, primary), 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(get-theme-color(brand, primary), 0);
  }
}