// Constants
$white: #fff;
$black: #141414;
$grey: #495057;
$background-color: #f6f8fa;
$border-color: #ced4da;
$heading-color: #141414;

$state-info: #039bd8;
$state-success: #90cc62;
$state-pending: #6554a5;
$state-warn: #f8b63a;
$state-danger: #bf2012;

$brand-primary: #3e89c6;
$brand-primary-dark: #001b2e;
$brand-secondary: #000000;

$default-border-radius: 3px;
$default-border-radius-extended: 10px;
$default-opacity-factor: 0.135;
$default-box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, $default-opacity-factor);
$default-heavy-box-shadow: 0px 5px 8px 0px
rgba(0, 0, 0, $default-opacity-factor * 4);

$default-font-family: 'Gellix-Regular', Helvetica, Arial, sans-serif;
$default-font-family-medium: 'Gellix-Medium', Helvetica, Arial, sans-serif;
$default-font-family-bold: 'Gellix-Bold', Helvetica, Arial, sans-serif;
$default-font-size: 13px;
$heading-scale: 2;

$targetSideNavWidth: 500px;

$tdd-colors: (
        basic: (
                white: $white,
                black: $black,
        ),
        borders: (
                base: $border-color,
                light: lighten($border-color, 10%),
                dark: darken($border-color, 10%),
        ),
        headings: (
                base: $heading-color,
                light: #ebebeb,
                dark: #d5d5d5,
        ),
        text: (
                base: $grey,
                light: lighten($grey, 35%),
                dark: darken($grey, 10%),
        ),
        background: (
                base: $background-color,
                light: lighten($background-color, 1.5%),
                medium: darken($background-color, 1.5%),
                grey: #ebebeb,
                white: $white,
        ),
        state: (
                base: $state-info,
                info: $state-info,
                success: $state-success,
                pending: $state-pending,
                warn: $state-warn,
                danger: $state-danger,
                secondary: $grey,
        ),
        brand: (
                base: $brand-primary,
                primary: $brand-primary,
                secondary: $brand-secondary,
                primary-dark: $brand-primary-dark,
                primary-light: lighten($brand-primary, 40),
                secondary-dark: darken($brand-secondary, 15%),
                secondary-light: lighten($brand-secondary, 15%),
        ),
);

$font-weight: (
        regular: 400,
        semibold: 600,
        bold: 800,
);

$tdd-component-widths: (
        navigation: (
                minimized: 75px,
                maximized: 300px,
        ),
);

$tdd-component-heights: (
        headers: 64px,
);

$breakpoints: (
        phone: 400px,
        phone-wide: 480px,
        phablet: 560px,
        tablet-small: 640px,
        tablet: 768px,
        tablet-wide: 1024px,
        desktop: 1248px,
        desktop-wide: 1440px,
);
