@import '/src/theme/theme-functions';
@import '/src/theme/theme-mixins';

.get-hired-with-hiro{
  .hiro-section-content{
    flex-direction: column;
  }
}
.hiro-get-hired-steps {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  
    .hiro-get-hired-step {
      background: get-theme-color(brand, primary-dark);
      border: 1px solid;
      border-image-slice: 1;
      border-image-source: linear-gradient(to right, get-theme-color(brand, primary-dark), get-theme-color(brand, primary));
      box-shadow: 0 0 20px 6px rgba(get-theme-color(brand, primary), .1);
      padding: 25px;
      position: relative;
      @include flex-column(15px);
      place-content: start start;

      .hiro-get-hired-step-content {
        @include flex-column(15px);
      }

      .hiro-get-hired-step-title {
        color: get-theme-color(basic, white);
        font-size: 1em;
      }

      .hiro-get-hired-step-number {
        font-weight: bold;
        font-size: 6em;
        color: get-theme-color(brand, primary);
      }

      .hiro-paragraph {
        color: get-theme-color(basic, white);
        line-height: 30px;
      }
    }
  }

.is-large, .is-tablet, .is-mobile{
  .hiro-page-section {
    .hiro-get-hired-steps{
      grid-template-columns: auto;
      grid-column-gap: 15px;
      .hiro-get-hired-step-number{
        display:none;
      }
      .hiro-hire-process-step{
        @include flex-column(0);
      }
      .hiro-get-hired-step-prefix{
        font-size: 12px;
        font-weight: normal;
        font-family: "Varela Round", sans-serif;
        text-transform: uppercase;
        letter-spacing: 5px;
        margin-bottom: 15px;
        color:get-theme-color(brand);
        display:block !important;
      }
    }
  }
}