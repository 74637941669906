@import './src/theme/theme-functions';
@import './src/theme/theme-mixins';

.hiro-paragraph{
  margin: 0;
  color:get-theme-color(text);
  line-height: 40px;
  max-width: 600px;
  &.is-dark{
    color:get-theme-color(basic, black);
  }
}

.is-tablet{
  .hiro-paragraph{
    line-height: 40px;
    font-size: 18px;
  }
}

.is-mobile{
  .hiro-paragraph{
    line-height: 30px;
    font-size: 14px;
    max-width: 600px;
  }
}
