@import '/src/theme/theme-functions';
@import '/src/theme/theme-mixins';

.hiro-page-section {
 
  &.process {
    .hiro-section-content{
      @include flex-column(25px);
    }
  }
}

.is-large, .is-tablet, .is-mobile{
  .hiro-page-section {
    &.process {
      .hiro-hire-process{
        display: grid;
        grid-template-columns: auto;
      }
    }
  }
}
.is-tablet{
  .hiro-page-section{
    &.process {
      padding: 0 150px 150px 150px;
    }
  }
}
.is-mobile{
  .hiro-page-section {
    &.process {
      padding-top: 0;
      .hiro-hire-process-step{
        @include flex-column(0);
      }
      .hiro-hire-process-step-number{
        display:none;
      }
      .hiro-hire-process-step-prefix{
        font-size: 12px;
        font-weight: normal;
        font-family: "Varela Round", sans-serif;
        text-transform: uppercase;
        letter-spacing: 5px;
        margin-bottom: 15px;
        color:get-theme-color(brand);
        display:block !important;
      }
    }
  }
}