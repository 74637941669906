@import './src/theme/theme-functions';
@import './src/theme/theme-mixins';

.hiro-button {
  @include button-style();

  .hiro-button-loading-icon{
    margin-right:10px;
  }
  &.thiro-button-ghost {
    @include button-style-ghost();
  }
}