@import '/src/theme/theme-functions';
@import '/src/theme/theme-mixins';

.hiro-header{
  background:get-theme-color(brand, primary-dark);
  padding: 0 100px;
  @include flex-row(35px);
  place-content: space-between;
  position:fixed;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 0 0 20px 6px rgba(get-theme-color(brand, primary-dark), .3);
  .hiro-logo-wrapper{
    width: 100px;
    background:get-theme-color(brand, primary);
    padding: 15px 0 25px 0;
    
    img{
      display: block;
      max-height: 65px;
      margin: 0 auto;
    }
  }
  .breakpoint__large-up{
    @include flex-row(0);
    place-content:center;
  }
  .hiro-navigation{
    @include flex-row(15px);
    align-items: center;
    place-content:center center;
    .hiro-navigation-item{
      background:transparent;
      color:get-theme-color(basic, white);
      border: none;
      line-height: 25px;
      padding: 5px 15px;
      border-radius:35px;
      transition: all .3s ease-in-out;
      text-transform: uppercase;
      text-decoration: none;
      font-size: .65em;
      letter-spacing: 2px;
      &.active, &:hover{
        background:get-theme-color(brand, primary);
        
      }
    }
    
    .hiro-phone{
      color:white;
      text-decoration:none;
      font-size:.70em;
      letter-spacing: 2px;
    }
  }
}

.is-mobile{
  .hiro-header{
    padding: 0 25px;
    .hiro-logo-wrapper{
      width: 100px;
    }
  }
}