@import '/src/theme/theme-functions';
@import '/src/theme/theme-mixins';


.hire-with-hiro {
  background: get-theme-color(brand, primary-dark);
  @include flex-row(50px);

  .hiro-page-section-heading {
    flex: 1 1 50%;
  }

  .hire-with-hiro-graphic {
    flex: 1 1 50%;
    @include flex-column(0);
    align-items: center;
    place-content: center;
    padding: 50px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
    max-height: 500px;
  }
}

.is-large {
  .hiro-page-section {
    &.hire-with-hiro {
      .hiro-section-content{
        margin: 0 auto;
        flex-direction: column;
      }
    }
  }
}

.is-tablet {
  .hire-with-hiro-graphic{
    display: none;
  }
  .hiro-page-section {
    &.hire-with-hiro {
      padding: 150px 150px 0 150px;
      .hiro-section-content{
        max-width: 800px;
        margin: 0 auto;
        flex-direction: column;
      }
    }
  }
}

.is-mobile {
  .hire-with-hiro-graphic{
    display: none;
  }
  .hiro-page-section {
    &.hire-with-hiro {
      .hiro-section-content{
        max-width: 800px;
        margin: 0 auto;
        flex-direction: column;
      }
    }
  }
}
