@import '/src/theme/theme-functions';
@import '/src/theme/theme-mixins';

.accent {
  color: get-theme-color(brand, primary);
}

.hiro-page-section {
  &.intro {
    @include flex-row(50px);
    padding-top: 200px;
    place-content:center;
    align-items:center;
    .hiro-page-section-heading {
      flex: 0 0 50%;
    }

    .hiro-actions {
      margin-top: 0;
    }

    .hiro-intro-actions {
      @include flex-row(15px);
      margin-top: 45px;
    }

   
    .hiro-intro-graphic {
      position: relative;
      display: flex;
      @include flex-column(0);
      place-content:center;
      align-items:center;
    }
  }
}

.is-large{
  .hiro-page-section {
    //padding: 125px;
    flex-direction: column;
    &.intro {
      .hiro-intro-graphic{
        min-width: 400px;
        position: relative;
        
      }
    }}
}

.is-tablet{
  .intro{
   // padding: 150px;
    .hiro-page-section-heading {
      flex: 1 1 100%;
    }
  }
}

.is-mobile{
  .intro{
    padding-top: 200px !important;
    .hiro-page-section-heading {
      flex: auto;
    }
  }
}



