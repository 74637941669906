@import '/src/theme/theme-functions';
@import '/src/theme/theme-mixins';

.about-us{
 
  .hiro-title, .hiro-subtitle, .hiro-paragraph{
    color: get-theme-color(basic, black);
  }
  
  .hiro-upper-content{
    > div {flex: 0 0 50%;}
  }

  .hiro-about-us-image{
    width: 100%;
    @include box-shadow();
    border-radius: 10% 0;
  }
}



