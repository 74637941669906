@import './src/theme/theme-functions';
@import './src/theme/theme-mixins';

.hiro-page-section{
  overflow:hidden;
  @include flex-row(50px);
  place-content: center;
  align-items: center;
  padding: 100px 50px;
  .hiro-section-content{
    max-width: 1200px;
    @include flex-column(50px);
    align-items: center;
    
    .hiro-upper-content{
      @include flex-row(50px);
      width: 100%;
      flex: 0 0 100%;
    }
    .hiro-lower-content{
      width: 100%;
      flex: 0 0 100%;
    }
  }
  
  &.is-full-content{
    .hiro-upper-content,.hiro-lower-content{
      flex: 1 1 100%;
    }
  }
  
  &.is-restricted{
    &.restrict-container{
      >.hiro-section-content {
        margin: 0 auto;
        border-radius: $default-border-radius-extended;
      }
    }
    
    &.restrict-content{
      >.hiro-section-content {
        margin: 0 auto;
        width: 1000px;
      }
    }
  }

  &.is-centered{
    place-content:center;
    align-items:center;
    text-align:center;
    *{
      place-content:center;
      align-items:center;
      text-align:center;
    }
    .hiro-paragraph{
      text-align:center;
      max-width: 700px;
    }
    .hiro-subtitle{
      position:relative;
      top: 0;
      left: 0;
      transform: none;
    }
  }
  
  &.is-primary{
    background:lighten(get-theme-color(brand, primary), 45);
  }
  
  &.is-secondary{
    background:get-theme-color(background);
  }
  
  &.is-dark{
    background:get-theme-color(basic, black);
  }
  &.is-image{
    background-size: cover;
    background-repeat: no-repeat;
  }
}


.is-tablet{
  .hiro-page-section{
    &.is-restricted{
      &.restrict-container{
        >div {
          width: 80%;
        }
      }

      &.restrict-content{
        >div {
          margin: 0 auto;
          width: 80%;
        }
      }
    }
    
    .hiro-section-content{
      .hiro-upper-content{
       flex-direction:column; 
      }
    }
  }
}


.is-mobile{
  .hiro-page-section{
    &.is-restricted{
      &.restrict-container{
        >.hiro-section-content {
          @include flex-column(15px);
          margin: 0 auto;
          width: 100%;
          border-radius: $default-border-radius-extended;
          
        }
      }

      &.restrict-content{
        >.hiro-section-content {
          @include flex-column(15px);
          margin: 0 auto;
          width: 100%;
        }
      }
    }

    .hiro-section-content {
      @include flex-column(15px);
    }

    .hiro-subtitle{
      transform: none;
      position: relative;
      top: 0;
      left: 0;
    }
  }
}


