@import '/src/theme/theme-functions';
@import '/src/theme/theme-mixins';


.hiro-roles-we-supply-list {
  flex: 1 1 100%;
  display:grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  .hiro-roles-we-supply-list-item {
    color:get-theme-color(brand, primary);
    .hiro-roles-we-supply-list-item-title{
      color:get-theme-color(basic, white);
      font-size: 1em;  
    }
  }
}

.is-tablet{
  .hiro-roles-we-supply-list{
    grid-template-columns: 1fr 1fr;

  }
}

.is-mobile{
  .hiro-roles-we-supply-list{
    grid-template-columns: 1fr;
    .avatar .hiro-avatar-image, .avatar .hiro-avatar-technology-logos{
      display:none !important;
    }
  }
}