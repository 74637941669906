@import './src/theme/theme-functions';
@import './src/theme/theme-mixins';

.hiro-tiles{
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  .hiro-tile{
    background:get-theme-color(brand, primary-dark);
    border:1px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(to right, get-theme-color(brand, primary-dark), get-theme-color(brand, primary));
    box-shadow: 0 0 20px 6px rgba(get-theme-color(brand, primary), .1);
    padding: 25px 50px 55px 50px;
    position:relative;
    @include flex-row(50px);
    place-content: start start;
    .hiro-tile-prefix{
      display:none;
    }
    .hiro-tile-title{
      color:get-theme-color(basic, white);
      font-size: 2em;
    }
    .hiro-tile-number{
      font-weight: bold;
      font-size: 6em;
      color:get-theme-color(brand, primary);
    }
    .hiro-paragraph{
      color:get-theme-color(basic, white);
    }
    a{
      color:get-theme-color(brand, primary);
      text-decoration: none;
    }
  }
}

.is-tablet{
  .hiro-tiles, .hiro-tile{
    @include flex-column(0);
  }
}

.is-mobile{
  .hiro-tiles, .hiro-tile{
    @include flex-column(0);
  }
  .hiro-tile{
   padding: 15px 15px 25px 15px !important; 
  }
  .hiro-tile-number{
    display:none;
  }
  .hiro-tile-prefix{
    font-size: 12px;
    font-weight: normal;
    font-family: "Varela Round", sans-serif;
    text-transform: uppercase;
    letter-spacing: 5px;
    margin-bottom: 15px;
    color:get-theme-color(brand);
    display:block !important;
  }
}