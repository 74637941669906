@import '/src/theme/theme-functions';
@import '/src/theme/theme-mixins';

.hiro-contact-form, .hiro-form-submitted{
  //background: #00000047;
  @include border-radius(15px);
  @include flex-column(25px);
  //padding: 25px;
  .hiro-contact-form-footer{
    @include flex-row(10px);
    place-content:end;
    width: 100%;
  }
  .hiro-button{
    width: 100%;
  }
  .hiro-paragraph{
    color: white;
  }
}

.hiro-form-submitted{
  color: get-theme-color(basic, white);
  min-height: 350px;
  display:flex;
  flex-direction:column;
  place-content:center center;
  align-items:center;
  text-align:center;
  gap:5px;
  .fa-circle-check{
    font-size:3em;
    color:get-theme-color(brand, primary);
  }
}

.hiro-contact-form-fields{
  @include flex-column(15px);
  place-content: start;
  align-items: start;
  width: 100%;
  .hiro-form-field{
    @include flex-column(5px);
    place-content: start;
    align-items: start;
    width: 100%;
    .hiro-form-field-label{
      @include font-family-medium();
      color: get-theme-color(basic, white);
      font-size: .85em;

      .hiro-form-label-required-marker{
        color: get-theme-color(brand);
      }
    }
    
    .p-selectbutton{
      .p-button{
        display:inline-flex;
        &:first-child{
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
        &:last-child{
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
        
        &.p-highlight{
          background:get-theme-color(brand, primary);
          border-color:get-theme-color(brand, primary);
        }
      }
    }
  }
}
.hiro-form-control{
  background:rgba(255, 255, 255, 1);
  @include border-radius();
  font-family: $default-font-family;
  color: get-theme-color(basic, black);
  padding: 0 15px;
  font-size: 18px;
  border: 1px solid transparent;
  width: 93%;
  &:focus, &:active, &:visited, &:hover, &:focus-visible{
    border: 1px solid get-theme-color(brand, primary);
    outline: get-theme-color(brand, primary);
  }
}
.hiro-form-control-text{
  min-height: 50px;
  line-height: 50px;

}

.hiro-form-control-textarea{
  height: 150px;
  line-height: 50px;
}

.hiro-form-control-error{
  color: get-theme-color(brand, primary);
  font-size: .75em;
  @include font-family-medium();
  text-align: right;

}


.is-mobile, .is-tablet{
  .contact-us-content{
    @include flex-column(25px);
  }
}